import * as React from "react";
import EarthBorder from "../../assets/bg-border.png";
function Section9() {
  return (
    <section className="px-0 pt-0 w-full bg-blue-400">
      <div className="flex justify-center w-full items-center lg:py-20 max-lg:py-12 max-md:px-5 overflow-hidden position-relative max-w-7xl max-lg:px-10 max-md:pt-0">
        <div className="hidden lg:flex max-xl:w-full w-full items-center justify-center position-absolute">
          <img
            loading="lazy"
            src={EarthBorder}
            alt="earth-border"
            className="position-absolute z-0"
          />
        </div>
        <div className="w-full max-md:max-w-full z-10">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 justify-center max-md:ml-0 max-md:w-full ml-10">
              <div className="flex flex-col justify-center text-white max-md:mt-10 max-md:max-w-full">
                <div className="text-[40px] font-bold leading-[54px] max-md:max-w-full max-md:text-center">
                  Connect Globally, Market Locally
                </div>
                <div className="text-[20px] font-medium leading-9 mt-10 max-md:max-w-full max-md:text-center">
                  Effortlessly reach customers worldwide with messaging tailored
                  for each region.
                </div>
              </div>
            </div>
            <div className="flex flex-col md:ml-16 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow text-2xl font-bold leading-9 text-white max-md:mt-10 max-md:max-w-full ">
                <div className="flex gap-5 justify-between ">
                  <span className="mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="20"
                      viewBox="0 0 29 20"
                      fill="none"
                    >
                      <path
                        d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                        fill="white"
                      />
                      <path
                        d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                    Tailored campaigns for local markets.
                  </div>
                </div>
                <div className="flex gap-5 justify-between mt-8 ">
                  <span className="mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="20"
                      viewBox="0 0 29 20"
                      fill="none"
                    >
                      <path
                        d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                        fill="white"
                      />
                      <path
                        d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                    Simple global compliance navigation.
                  </div>
                </div>
                <div className="flex gap-5 justify-between mt-8 ">
                  <span className="mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="20"
                      viewBox="0 0 29 20"
                      fill="none"
                    >
                      <path
                        d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                        fill="white"
                      />
                      <path
                        d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                    Quick local number setup.
                  </div>
                </div>
                <div className="flex gap-5 justify-between mt-8 ">
                  <span className="mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="20"
                      viewBox="0 0 29 20"
                      fill="none"
                    >
                      <path
                        d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                        fill="white"
                      />
                      <path
                        d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                    Feel local, reach global.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Section9;

