import * as React from "react";
import Content from "./content";

function Section14() {
  const [isMonthly, setMonthly] = React.useState(false);
  return (
    <section className="w-full lg:mx-auto max-md:px-8">
      <div className="flex flex-col justify-center items-center py-8 my-5 md:px-16 mx-2.5 lg:max-w-7xl max-md:w-full">
        <div className="text-[36px] font-bold text-center leading-[60px] mb-10">
          SMS Pricing for Ecommerce Marketing
        </div>
        <div className="flex justify-center items-center">
          <div className="text-[15px] font-bold text-center  mr-4">Monthly</div>
          <label className="switch">
            <input
              type="checkbox"
              checked={isMonthly}
              onChange={() => setMonthly(!isMonthly)}
            />
            <span className="slider round"></span>
          </label>
          <div className="text-[15px] font-bold text-center  ml-4">Yearly</div>
        </div>
        <div className="flex justify-center fle-wrap max-md:flex-col max-md:px-5 items-center py-12 rounded-xl position-relative lg:gap-10 lg:px-32">
          {Content.map((item) => (
            <div
              key={item.id}
              className=" flex justify-start border-2 border-gray-300 items-center flex-col py-10 lg:px-20 max-md:px-14 max-md:mb-10 rounded-lg"
            >
              <div className="text-[36px] font-bold text-center leading-[60px]">
                {item?.title}
              </div>
              <div className=" text-[15px] font-normal flex justify-start items-center">
                {!isMonthly ? item?.planM : item?.planY}
              </div>

              <div className="text-[25px]  mt-10 font-bold  flex justify-start items-center">
                {" "}
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="20"
                    viewBox="0 0 29 20"
                    fill="none"
                  >
                    <path
                      d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                      fill="#232323"
                    />
                    <path
                      d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                      fill="#46A0EE"
                    />
                  </svg>
                </span>
                <span className="font-bold mr-5 text-[20px]"> Setup cost:</span>{" "}
                $2500
              </div>
              <div className="h-[1px] bg-blue-400 w-full my-5"></div>
              <div className="text-[25px] font-bold  flex justify-start items-center">
                {" "}
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="20"
                    viewBox="0 0 29 20"
                    fill="none"
                  >
                    <path
                      d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                      fill="#232323"
                    />
                    <path
                      d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                      fill="#46A0EE"
                    />
                  </svg>
                </span>
                <span className="font-bold mr-5 text-[20px]"> SMS:</span>{" "}
                {!isMonthly ? item?.monthPriceSMS : item?.yearPriceSMS}
              </div>

              <span className="font-normal text-[18px] flex justify-end items-end">
                + carrier fee
              </span>
              <div className=" text-[25px] font-bold mt-5  flex justify-start items-center">
                {" "}
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="20"
                    viewBox="0 0 29 20"
                    fill="none"
                  >
                    <path
                      d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                      fill="#232323"
                    />
                    <path
                      d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                      fill="#46A0EE"
                    />
                  </svg>
                </span>
                <span className="font-bold mr-5 text-[20px]"> MMS: </span>
                {!isMonthly ? item?.monthPriceMMS : item?.yearPriceMMS}
              </div>

              <span className="font-normal text-[18px] flex justify-end items-end">
                + carrier fee
              </span>

              {/* <div className="text-[15px] font-normal text-center text-white my-10">
                {item?.description}
              </div> */}
              {/* <div className="hidden lg:flex lg:flex-1 mt-10 lg:justify-end gap-5 font-semibold text-center text-blue-400">
                <button className="justify-center px-5 py-2 my-auto bg-blue-400 rounded h-[45px] text-white">
                  {item?.buttonText}
                </button>
              </div> */}
            </div>
          ))}
        </div>
      </div>{" "}
    </section>
  );
}
export default Section14;

