import * as React from "react";
import CampaignContactImg from "../../assets/Campaign Contact.png";
import CampaignMsg from "../../assets/CampaignMSG.png";
import { Link } from "react-router-dom";

function Section8() {
  return (
    <section className="bg-sky">
      <div className="max-w-7xl xl:py-10 max-xl:px-5 position-relative max-lg:px-12  max-md:px-5">
        <div className="flex max-xl:flex-col max-md:gap-0 position-relative">
          <div className="flex flex-col w-[45%] max-md:ml-0 max-xl:w-full xl:pt-10">
            <div className="flex flex-col self-stretch my-auto text-2xl font-bold leading-9 max-md:mt-10 max-xl:max-w-full">
              <div className="text-[40px] leading-[54px] font-bold max-md:max-w-full md:mr-10 max-md:text-center">
                Check Campaign Costs Before Spending
              </div>
              <div className="mt-5 text-[20px] font-medium leading-[34px]  max-md:max-w-full max-md:text-center">
                Preview and adjust your SMS campaign expenses before sending,
                ensuring cost-effective marketing.
              </div>
              <div className="flex gap-5 justify-between mt-5 ">
                <span className="mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="21"
                    viewBox="0 0 29 21"
                    fill="none"
                  >
                    <path
                      d="M15.4069 9.14733L1.46518 0.635183C0.367593 -0.0420526 -0.531499 1.98965 0.36759 3.08724L5.51691 9.39254C5.99565 9.97636 5.99565 11.0272 5.51691 11.6111L0.36759 17.9047C-0.531499 19.0139 0.367593 21.034 1.46518 20.3684L15.4069 11.8446C16.2359 11.3308 16.2359 9.66109 15.4069 9.14733Z"
                      fill="#232323"
                    />
                    <path
                      d="M28.263 9.14733L14.3329 0.635183C13.2354 -0.0420526 12.3246 1.98965 13.2237 3.08724L18.373 9.39254C18.8517 9.97636 18.8517 11.0272 18.373 11.6111L13.2237 17.9047C12.3246 19.0139 13.2354 21.034 14.3329 20.3684L28.263 11.8446C29.1037 11.3308 29.1037 9.66109 28.263 9.14733Z"
                      fill="#46A0EE"
                    />
                  </svg>
                </span>
                <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                  Know costs before sending.
                </div>
              </div>
              <div className="flex gap-5 justify-between mt-8 ">
                <span className="mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="21"
                    viewBox="0 0 29 21"
                    fill="none"
                  >
                    <path
                      d="M15.4069 9.14733L1.46518 0.635183C0.367593 -0.0420526 -0.531499 1.98965 0.36759 3.08724L5.51691 9.39254C5.99565 9.97636 5.99565 11.0272 5.51691 11.6111L0.36759 17.9047C-0.531499 19.0139 0.367593 21.034 1.46518 20.3684L15.4069 11.8446C16.2359 11.3308 16.2359 9.66109 15.4069 9.14733Z"
                      fill="#232323"
                    />
                    <path
                      d="M28.263 9.14733L14.3329 0.635183C13.2354 -0.0420526 12.3246 1.98965 13.2237 3.08724L18.373 9.39254C18.8517 9.97636 18.8517 11.0272 18.373 11.6111L13.2237 17.9047C12.3246 19.0139 13.2354 21.034 14.3329 20.3684L28.263 11.8446C29.1037 11.3308 29.1037 9.66109 28.263 9.14733Z"
                      fill="#46A0EE"
                    />
                  </svg>
                </span>
                <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                  Measure spending against earnings.
                </div>
              </div>
              <div className="flex gap-5 justify-between mt-8 ">
                <span className="mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="21"
                    viewBox="0 0 29 21"
                    fill="none"
                  >
                    <path
                      d="M15.4069 9.14733L1.46518 0.635183C0.367593 -0.0420526 -0.531499 1.98965 0.36759 3.08724L5.51691 9.39254C5.99565 9.97636 5.99565 11.0272 5.51691 11.6111L0.36759 17.9047C-0.531499 19.0139 0.367593 21.034 1.46518 20.3684L15.4069 11.8446C16.2359 11.3308 16.2359 9.66109 15.4069 9.14733Z"
                      fill="#232323"
                    />
                    <path
                      d="M28.263 9.14733L14.3329 0.635183C13.2354 -0.0420526 12.3246 1.98965 13.2237 3.08724L18.373 9.39254C18.8517 9.97636 18.8517 11.0272 18.373 11.6111L13.2237 17.9047C12.3246 19.0139 13.2354 21.034 14.3329 20.3684L28.263 11.8446C29.1037 11.3308 29.1037 9.66109 28.263 9.14733Z"
                      fill="#46A0EE"
                    />
                  </svg>
                </span>
                <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                  Convert credits to clear costs.
                </div>
              </div>
              <div className="flex gap-5 justify-between mt-8 ">
                <span className="mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="21"
                    viewBox="0 0 29 21"
                    fill="none"
                  >
                    <path
                      d="M15.4069 9.14733L1.46518 0.635183C0.367593 -0.0420526 -0.531499 1.98965 0.36759 3.08724L5.51691 9.39254C5.99565 9.97636 5.99565 11.0272 5.51691 11.6111L0.36759 17.9047C-0.531499 19.0139 0.367593 21.034 1.46518 20.3684L15.4069 11.8446C16.2359 11.3308 16.2359 9.66109 15.4069 9.14733Z"
                      fill="#232323"
                    />
                    <path
                      d="M28.263 9.14733L14.3329 0.635183C13.2354 -0.0420526 12.3246 1.98965 13.2237 3.08724L18.373 9.39254C18.8517 9.97636 18.8517 11.0272 18.373 11.6111L13.2237 17.9047C12.3246 19.0139 13.2354 21.034 14.3329 20.3684L28.263 11.8446C29.1037 11.3308 29.1037 9.66109 28.263 9.14733Z"
                      fill="#46A0EE"
                    />
                  </svg>
                </span>
                <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                  Budget-friendly campaign planning.
                </div>
              </div>
              <Link
                without="true"
                rel="noreferrer"
                className="justify-center max-md:self-center md:self-start px-5 py-2 mt-11 text-lg font-semibold text-center text-white bg-blue-400 rounded max-md:mt-10  h-[45px] hover:bg-white hover:text-[#46a0ee] hover:border-[#46a0ee]"
                to="https://calendly.com/simplesms-sales/30min?month=2024-08"
              >
                Book a Demo
              </Link>{" "}
            </div>
          </div>
          <div className="flex flex-col w-[58%] -ml-10 max-md:ml-0 max-xl:w-full position-relative items-start justify-start max-md:my-10">
            <img
              alt="section-8-contact"
              loading="lazy"
              src={CampaignContactImg}
              className="w-full"
            />
            <img
              alt="section-8-campaign "
              loading="lazy"
              src={CampaignMsg}
              className="w-[300px] h-auto lg:w-[512px] position-absolute right-1 xl:-right-28 xl:-bottom-2 bottom-0"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
}
export default Section8;
