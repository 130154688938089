import * as React from "react";
import SVector from "../../assets/s-vector.png";
import Sqaure from "../../assets/sqaure.png";
import { toast } from "react-toastify";
import sendBrevoEmail from "../../apis/email";
import { emailTemplateJoinUs } from "../../utils/template";

function Section13() {
  const [email, setEmail] = React.useState("");
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const onSubmit = async () => {
    if (email !== "") {
      if (!isValidEmail(email)) {
        toast("Email is invalid! ");
      } else {
        await sendBrevoEmail(
          "POST",
          {},
          {
            to: [
              {
                email:
                  process.env.REACT_APP_BREVO_RECEIVER || "admin@simplesms.com",
                name: "Simple SMS Admin",
              },
            ],
            subject: "New Form Submission Received",
            body: emailTemplateJoinUs(email),
          }
        );

        toast("Successfully submitted!");
        setEmail("");
      }
    } else if (email === "") {
      toast("Email field is Required!");
    }
  };
  return (
    <section className="max-w-7xl mx-auto max-md:px-5">
      <div className="flex justify-center items-center py-8 my-5 md:px-16 mx-2.5">
        <div className="flex justify-center items-center py-12 bg-blue-400 rounded-xl position-relative">
          <div className="flex justify-center items-center flex-col max-lg:px-5">
            <div className="text-[36px] font-bold text-center text-white leading-[60px]">
              Navigate to Simpler SMS Management
            </div>
            <div className="mt-8 text-[22px] z-10 font-medium leading-[34px] text-center text-white max-md:max-w-full max-lg:px-20 max-md:px-5 lg:px-36">
              Learn the secrets of smooth SMS platform transition, optimizing
              both cost and functionality, with our insightful guide.
            </div>
            <div
              style={{ zIndex: 9 }}
              className="flex gap-2.5 justify-between self-center px-5 py-2.5 mt-10 max-w-full text-lg bg-gray-50 rounded  max-md:flex-wrap max-md:mt-10 flex-wrap lg:w-[420px] max-md:justify-center max-md:py-2 max-md:px-2"
            >
              <input
                type="email"
                className="my-auto font-medium text-slate-500 bg-transparent outline-none max-md:my-2"
                placeholder=" Enter Your E-mail"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />

              <button
                className="justify-center px-5 py-2 font-bold text-center text-white whitespace-nowrap bg-blue-400  h-[45px] rounded"
                onClick={() => onSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
          <img
            loading="lazy"
            src={Sqaure}
            alt="earth"
            className="hidden md:flex"
            style={{
              position: "absolute",
              overflow: "hidden",
              height: "100%",
              left: 0,
            }}
          />
          <img
            src={SVector}
            loading="lazy"
            className="hidden md:flex"
            alt="earth-border"
            style={{
              right: 0,
              bottom: 0,
              position: "absolute",
              overflow: "hidden",
            }}
          />
        </div>
      </div>{" "}
    </section>
  );
}
export default Section13;

