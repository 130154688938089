import * as React from "react";
import Frame from "../../assets/TransparentCostFrame.png";
import Chart from "../../assets/chart.png";
import Table from "../../assets/table.png";
function Section10() {
  return (
    <section className="max-w-7xl mx-auto max-xl:px-5 py-20 max-md:py-10">
      <div className="flex justify-between bg-white max-lg:flex-col-reverse">
        <div className="flex flex-col w-[69%] justify-center max-lg:w-full max-lg:mt-10">
          <img
            loading="lazy"
            src={Chart}
            className="max-lg:w-full lg:w-[867px]"
            alt="frame"
          />
        </div>
        <div className="flex-col w-[31%] md:ml-6 lg:ml-5 font-bold leading-[50px] lg:mt-10 max-lg:w-full">
          <div className="text-[36px] font-bold leading-[50px] max-md:text-center">
            Invest Wisely with Visible Campaign Costs
          </div>
          <div className="text-[20px] mt-5 font-medium leading-[34px] max-md:text-center">
            Track every dollar's impact on your ROI with meaningful metrics.
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center lg:-mt-12 lg:ml-52">
        <img
          loading="lazy"
          src={Frame}
          className="w-medium justify-center w-[576.76px] max-lg:w-full max-lg:px-3 "
          alt="frame"
        />
      </div>
      <img
        src={Table}
        alt="Table"
        className="lg:mt-10 max-lg:my-5 max-lg:w-full max-lg:px-3"
      />
    </section>
  );
}
export default Section10;
