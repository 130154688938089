const TextContent = [
  {
    id: 1,
    title: "Alex",
    description:
      " I was amazed by how intuitive and effective the SMS campaign tool is. Fantastic results with minimal effort!",
  },
  {
    id: 2,
    title: "Priya Gupta",
    description:
      "The instant campaign simulator is a game-changer! Saw an uplift in engagement right away.",
  },
  {
    id: 3,
    title: "Ethan Hunt",
    description:
      "Just what we needed! quick setup, simple to use, and no upfront costs. Our team loves it!.",
  },
  {
    id: 4,
    title: "Grace Novick",
    description:
      " Impressive! The simulator helped us understand the platform's potential before we dived in.",
  },

  {
    id: 5,
    title: "Liam Scott",
    description:
      "No credit card required to start? Incredible! The free trial gave us the confidence to commit.",
  },
  {
    id: 6,
    title: "Sophia Choi",
    description:
      "Brilliant service without the hassle of account creation. The tool is super user-friendly and effective.",
  },
  {
    id: 7,
    title: "Carlos Diaz",
    description:
      "I'm already seeing a boost in sales thanks to the trial. Will definitely continue using it!",
  },
  {
    id: 8,
    title: "Emma Johnson",
    description:
      "A seamless experience from the get-go. It's easy to try before you buy without any pressure.",
  },
];
export { TextContent };
