const BASE_URL =
  process.env.REACT_APP_BREVO_SERVER_API ||
  "https://api.brevo.com/v3/smtp/email";

const API_URL =
  process.env.REACT_APP_BREVO_API ||
  "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";

const sendBrevoEmail = async (method, header, body) => {
  return await fetch(BASE_URL, {
    method: method,
    headers: {
      accept: "application/json",
      "api-key": API_URL,
      ...header,
    },
    body: JSON.stringify({
      sender: {
        name: "Simple SMS",
        email: "noreply@simplesms.com",
      },
      to: [...body.to],
      subject: body?.subject,
      htmlContent: body?.body,
    }),
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });
};
export default sendBrevoEmail;
