import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TermsAndCondition = () => {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <section className="max-w-7xl mx-auto max-xl:px-5 py-20 max-md:px-8 max-md:py-10 flex items-start md:pl-8">
      <h1 className="flex self-center justify-center items-center text-4xl font-bold">
        Terms and Conditions
      </h1>
      <div className="font-bold text-xl mt-10">
        Last Updated: <span className="font-normal ">02-08-2024</span>
      </div>
      <div>
        These Terms and Conditions govern your access to and use of{" "}
        <a
          href={process.env.REACT_APP_URL || "https://staging.simplesms.com/"}
          className="underline text-blue-600"
        >
          {process.env.REACT_APP_URL || "https://staging.simplesms.com/"}
        </a>
        , operated by Simple SMS.
      </div>
      <div className="font-bold mt-5">1. Acceptance of Terms</div>
      <div>
        By accessing or using the Website, you agree to be bound by these Terms.
        If you disagree with any part of these Terms, you may not access or use
        the Website.
      </div>
      <div className="font-bold mt-5">2. User Eligibility</div>
      <div>
        You must be at least 18 years old and have the legal authority to enter
        into contracts to use the Website.
      </div>
      <div className="font-bold mt-5">
        3. SMS Marketing and Transactional Messages
      </div>
      <ul className="md:ml-10">
        <li className="font-bold mt-3 list-disc">
          Double Opt-In:
          <span className="font-normal">
            We use a double opt-in process for obtaining user consent to receive
            SMS messages. Users will receive an initial message requesting
            confirmation of their desire to receive SMS communications. Only
            after confirming their consent will they be added to our SMS list.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          TCPA and CTIA Compliance:
          <span className="font-normal">
            All SMS messages sent through our platform will comply with the
            Telephone Consumer Protection Act (TCPA) and the Cellular
            Telecommunications Industry Association (CTIA) guidelines. We will
            not send unsolicited SMS messages or messages to Do Not Call (DNC)
            registered numbers.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Opt-Out:
          <span className="font-normal">
            Users can opt-out of receiving SMS messages at any time by replying
            "STOP" to any message received.
          </span>
        </li>
      </ul>
      <div className="font-bold mt-5">4. Fees and Payment</div>
      <ul className="md:ml-10">
        <li className="font-bold mt-3 list-disc">
          Our Services:{" "}
          <span className="font-normal">
            Our SMS marketing and transactional services are available to all
            users.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Offline Payments:{" "}
          <span className="font-normal">
            Payment for our services will be made offline through methods agreed
            upon between you and the Company. Online payment options are not
            currently available.
          </span>
        </li>
      </ul>
      <div className="font-bold mt-5">5. User Content</div>
      <div>
        You are responsible for all content ("User Content") you upload, post,
        or transmit through the Website. You represent and warrant that you have
        all rights necessary to transmit such User Content and that such User
        Content does not infringe on any third-party rights.
      </div>
      <div className="font-bold mt-5">6. Disclaimer</div>
      <div>
        The Website and all content provided on the Website are provided "as is"
        and without warranties of any kind, express or implied. The Company
        disclaims all warranties, including but not limited to, warranties of
        merchantability, fitness for a particular purpose, and non-infringement.
      </div>
      <div>
        The Company does not warrant that the Website will be uninterrupted,
        error-free, or virus-free. The Company does not warrant that the results
        obtained from the use of the Website will be accurate or reliable.
      </div>
      <div className="font-bold mt-5">7. Limitation of Liability</div>
      <div>
        The Company will not be liable for any direct, indirect, incidental,
        consequential, special, or punitive damages arising out of or in
        connection with your use of the Website, even if the Company has been
        advised of the possibility of such damages.
      </div>
      <div className="font-bold mt-5">8. Governing Law</div>
      <div>
        These Terms shall be governed by and construed in accordance with the
        laws of the State of \[Your State\] without regard to its conflict of
        laws principles.
      </div>
      <div className="font-bold mt-5">9. Entire Agreement</div>
      <div>
        These Terms constitute the entire agreement between you and the Company
        regarding your use of the Website.
      </div>
      <div className="font-bold mt-5">10. Severability</div>
      <div>
        If any provision of these Terms is held to be invalid or unenforceable,
        such provision shall be struck and the remaining provisions shall remain
        in full force and effect.
      </div>
      <div className="font-bold mt-5">11. Amendment</div>
      <div>
        The Company reserves the right to amend these Terms at any time. The
        amended Terms will be effective upon posting on the Website. You are
        responsible for periodically reviewing the Terms. Your continued use of
        the Website following the posting of amended Terms constitutes your
        acceptance of the amended Terms.
      </div>
      <div className="font-bold mt-5">12. Contact Us</div>
      <div>
        If you have any questions about these Terms, please contact us at{"  "}
        <a
          href={
            "mailto:" + process.env.REACT_APP_EMAIL ||
            "mailto:admin@simplesms.com"
          }
        >
          {process.env.REACT_APP_EMAIL || "admin@simplesms.com"}
        </a>
      </div>
    </section>
  );
};
export default TermsAndCondition;
