import * as React from "react";
import section1 from "../../assets/gif/section1.gif";
import { Link } from "react-router-dom";

function Section1() {
  return (
    <section className="bg-hero-section max-md:pb-16 lg:pb-5 lg:pt-10">
      <div className="mx-auto flex max-w-7xl items-center justify-between">
        <div className="flex max-xl:flex-col w-full">
          <div className="flex flex-col xl:w-[55%] max-lg:ml-0 max-xl:w-full p-4 lg:pl-5 lg:pr-0 lg:pt-18">
            <div className="flex flex-col self-stretch my-auto text-2xl font-medium max-lg:mt-10 max-lg:max-w-full position-relative">
              <h1 className="text-[46px] font-extrabold leading-[70px] text-extrabold max-md:text-center">
                Optimize Spending, Accelerate Revenue & Fuel Sustainable Growth,
                Not Expenditures
              </h1>
              <div className="md:mt-12 text-[24px] leading-[40px] text-medium max-lg:max-w-full max-md:mt-5 xl:mr-5 max-md:text-center">
                Struggling to achieve reliable results despite overspending on
                Shopify SMS platforms? Cut down on SMS expenses and elevate
                Shopify sales with SimpleSMS!
              </div>
              <span className="hidden  w-[200px] text-[22px] leading-[37.49px] text-white px-2 py-1.5 bg-blue-400 section1--badge">
                Embrace Change
              </span>
              <div className="flex max-md:justify-center justify-between max-md:self-center self-start mt-14 text-lg font-semibold text-center max-md:flex-wrap max-md:mt-5">
                <button className="justify-center max-md:ml-3 max-md:mb-3 text-white bg-blue-400 rounded border border-blue-400 border-solid px-5 py-2 h-[45px]">
                  AI coming soon
                </button>
                <Link
                  without="true"
                  rel="noreferrer"
                  className="justify-center px-5 md:ml-10 max-md:ml-3 max-md:mb-3 py-2 rounded border border-solid border-zinc-800 h-[45px] hover:bg-zinc-800  hover:text-white"
                  to="https://calendly.com/simplesms-sales/30min?month=2024-08"
                >
                  Request a Demo
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col xl:w-[60%] max-md:ml-0 max-xl:w-full xl:-ml-[110px] xl:-mr-[80px] justify-center items-center">
            <div className="flex w-full justify-center items-center">
              <img
                // loading="lazy"
                src={section1}
                alt="section1"
                className="max-xl:h-full max-xl:w-full xl:h-[720px]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Section1;

