const TextContent = [
  {
    id: 1,
    title: "Effortless Integration",
    description:
      "Connect seamlessly with Shopify for smooth SMS campaign launches.",
  },
  {
    id: 2,
    title: "Automated Campaigns",
    description:
      "Implement SMS campaigns that respond to customer behaviors seamlessly.",
  },
  {
    id: 3,
    title: "Data-Driven Insights",
    description:
      "Use actionable analytics to fine-tune your strategies for better ROI.",
  },
  {
    id: 4,
    title: "Opt-in Tools",
    description: "Create a new opt-in tool and start growing your audience",
  },
];

const TextContent2 = [
  {
    id: 5,
    title: "Segmented Targeting",
    description:
      "Reach the right customers with messages tailored to their interests.",
  },
  {
    id: 6,
    title: "One To One SMS Communication",
    description:
      "Engage actively with your subscribers for effective marketing",
  },
  {
    id: 7,
    title: "Real-Time Analytics",
    description:
      "Monitor campaign performance and adjust in real-time for maximum impact.",
  },
  {
    id: 8,
    title: "Streamlined Compliance",
    description:
      "Ensure every message meets guidelines with our automated compliance checks.",
  },
];
export { TextContent, TextContent2 };
