import * as React from "react";
import RightIcon from "../../assets/right-top.png";
import { TextContent, TextContent2 } from "./textContent";
function Section4() {
  return (
    <section className="bg-sky lg:pb-16 lg:pt-14 lg:px-4">
      <div className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-4">
        <div className="flex flex-col justify-around max-md:py-8">
          <div className="flex flex-col self-center w-full text-center">
            <div className="text-4xl font-bold leading-[66px] max-md:max-w-full">
              Maximize ROI with Targeted Shopify SMS Marketing
            </div>
            <div className="self-end mt-8 lg:mx-32 text-xl font-medium leading-9 max-md:mr-2.5 max-md:max-w-full">
              Empower your Shopify store with SMS services that enhance customer
              trust and loyalty, driving impactful sales through strategic
              engagement.
            </div>
          </div>
          <div className="md:mt-16 w-full max-md:max-w-full">
            <div className="flex lg:gap-2 xl:gap-4 max-sm:flex-col md:gap-5 flex-wrap justify-center items-stretch">
              {TextContent.map((item) => (
                <div
                  className="flex flex-col w-full sx:w-[45%] md:w-[45%] lg:w-[24%] max-md:ml-0"
                  key={item?.title}
                >
                  <div className="flex flex-col grow items-start md:pb-8 max-md:pb-4 pl-5 w-full bg-white rounded-xl border border-solid shadow-lg border-stone-300 max-md:mt-10">
                    <div className="flex justify-center z-10 shrink-0 self-end items-center -mt-3 bg-white rounded border-2 border-blue-400 border-solid h-[50px] w-[50px]">
                      <img src={RightIcon} alt="right" loading="lazy" />
                    </div>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="54"
                        height="54"
                        viewBox="0 0 54 54"
                        fill="none"
                      >
                        <rect
                          width="54"
                          height="54"
                          rx="2"
                          fill="#CCCCCC"
                          fillOpacity="0.2"
                        />
                        <path
                          d="M28.5 37.5C28.5 38.0199 28.7692 38.5027 29.2114 38.776C29.6536 39.0493 30.2058 39.0741 30.6708 38.8416L36.6708 35.8416C37.179 35.5876 37.5 35.0682 37.5 34.5V25.8541C37.5 25.3342 37.2308 24.8514 36.7886 24.5781C36.3464 24.3048 35.7942 24.28 35.3292 24.5125L29.3292 27.5125C28.821 27.7666 28.5 28.2859 28.5 28.8541V37.5Z"
                          fill="#46A0EE"
                        />
                        <path
                          d="M34.8167 21.4146C35.3249 21.1605 35.6459 20.6411 35.6459 20.0729C35.6459 19.5048 35.3249 18.9854 34.8167 18.7313L27.6708 15.1584C27.2485 14.9472 26.7515 14.9472 26.3292 15.1584L19.1833 18.7313C18.6751 18.9854 18.3541 19.5048 18.3541 20.0729C18.3541 20.6411 18.6751 21.1605 19.1833 21.4146L26.3292 24.9875C26.7515 25.1987 27.2485 25.1987 27.6708 24.9875L34.8167 21.4146Z"
                          fill="#46A0EE"
                        />
                        <path
                          d="M18.6708 24.5125C18.2058 24.28 17.6536 24.3048 17.2114 24.5781C16.7692 24.8514 16.5 25.3342 16.5 25.8541V34.5C16.5 35.0682 16.821 35.5876 17.3292 35.8416L23.3292 38.8416C23.7942 39.0741 24.3464 39.0493 24.7886 38.776C25.2308 38.5027 25.5 38.0199 25.5 37.5V28.8541C25.5 28.2859 25.179 27.7666 24.6708 27.5125L18.6708 24.5125Z"
                          fill="#46A0EE"
                        />
                      </svg>
                    </span>
                    <div className="mt-5 text-xl font-bold leading-9 ">
                      {item?.title}
                    </div>
                    <div className="mt-2.5 text-base font-medium leading-7  mr-5">
                      {item?.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="md:mt-16 w-full max-md:max-w-full">
            <div className="flex lg:gap-2 xl:gap-4 max-sm:flex-col md:gap-5 flex-wrap justify-center items-stretch">
              {TextContent2.map((item) => (
                <div
                  className="flex flex-col w-full sx:w-[45%] md:w-[45%] lg:w-[24%] max-md:ml-0"
                  key={item?.title}
                >
                  <div className="flex flex-col grow items-start md:pb-8 max-md:pb-4 pl-5 w-full bg-white rounded-xl border border-solid shadow-lg border-stone-300 max-md:mt-10">
                    <div className="flex justify-center z-10 shrink-0 self-end items-center -mt-3 bg-white rounded border-2 border-blue-400 border-solid h-[50px] w-[50px]">
                      <img src={RightIcon} alt="right" loading="lazy" />
                    </div>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="54"
                        height="54"
                        viewBox="0 0 54 54"
                        fill="none"
                      >
                        <rect
                          width="54"
                          height="54"
                          rx="2"
                          fill="#CCCCCC"
                          fillOpacity="0.2"
                        />
                        <path
                          d="M28.5 37.5C28.5 38.0199 28.7692 38.5027 29.2114 38.776C29.6536 39.0493 30.2058 39.0741 30.6708 38.8416L36.6708 35.8416C37.179 35.5876 37.5 35.0682 37.5 34.5V25.8541C37.5 25.3342 37.2308 24.8514 36.7886 24.5781C36.3464 24.3048 35.7942 24.28 35.3292 24.5125L29.3292 27.5125C28.821 27.7666 28.5 28.2859 28.5 28.8541V37.5Z"
                          fill="#46A0EE"
                        />
                        <path
                          d="M34.8167 21.4146C35.3249 21.1605 35.6459 20.6411 35.6459 20.0729C35.6459 19.5048 35.3249 18.9854 34.8167 18.7313L27.6708 15.1584C27.2485 14.9472 26.7515 14.9472 26.3292 15.1584L19.1833 18.7313C18.6751 18.9854 18.3541 19.5048 18.3541 20.0729C18.3541 20.6411 18.6751 21.1605 19.1833 21.4146L26.3292 24.9875C26.7515 25.1987 27.2485 25.1987 27.6708 24.9875L34.8167 21.4146Z"
                          fill="#46A0EE"
                        />
                        <path
                          d="M18.6708 24.5125C18.2058 24.28 17.6536 24.3048 17.2114 24.5781C16.7692 24.8514 16.5 25.3342 16.5 25.8541V34.5C16.5 35.0682 16.821 35.5876 17.3292 35.8416L23.3292 38.8416C23.7942 39.0741 24.3464 39.0493 24.7886 38.776C25.2308 38.5027 25.5 38.0199 25.5 37.5V28.8541C25.5 28.2859 25.179 27.7666 24.6708 27.5125L18.6708 24.5125Z"
                          fill="#46A0EE"
                        />
                      </svg>
                    </span>
                    <div className="mt-5 text-xl font-bold leading-9 ">
                      {item?.title}
                    </div>
                    <div className="mt-2.5 text-base font-medium leading-7  mr-5">
                      {item?.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Section4;
