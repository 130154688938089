import Section1 from "../../components/Section1";
import Section2 from "../../components/Section2";
import Section3 from "../../components/Section3";
import Section4 from "../../components/Section4";
import Section5 from "../../components/Section5";
import Section6 from "../../components/Section6";
import Section7 from "../../components/Section7";
import Section8 from "../../components/Section8";
import Section9 from "../../components/Section9";
import Section10 from "../../components/Section10";
import Section11 from "../../components/Section11";
import Section14 from "../../components/Section14";
import Section13 from "../../components/Section13";
import { Element } from "react-scroll";

const router = [
  {
    id: 1,
    name: "Section1",
    component: (
      <Element name="element1" className="element">
        <Section1 />
      </Element>
    ),
  },
  {
    id: 2,
    name: "Section2",
    component: (
      <Element name="element2" className="element">
        <Section2 />
      </Element>
    ),
  },
  {
    id: 3,
    name: "Section3",
    component: (
      <Element name="element3" className="element">
        <Section3 />
      </Element>
    ),
  },
  {
    id: 4,
    name: "Section4",
    component: (
      <Element name="element4" className="element">
        <Section4 />
      </Element>
    ),
  },
  {
    id: 5,
    name: "Section5",
    component: (
      <Element name="element5" className="element">
        <Section5 />
      </Element>
    ),
  },
  {
    id: 6,
    name: "Section6",
    component: (
      <Element name="element6" className="element">
        <Section6 />
      </Element>
    ),
  },
  {
    id: 7,
    name: "Section7",
    component: (
      <Element name="element7" className="element">
        <Section7 />
      </Element>
    ),
  },
  {
    id: 8,
    name: "Section8",
    component: (
      <Element name="element8" className="element">
        <Section8 />
      </Element>
    ),
  },
  {
    id: 9,
    name: "Section9",
    component: (
      <Element name="element9" className="element">
        <Section9 />
      </Element>
    ),
  },
  {
    id: 10,
    name: "Section10",
    component: (
      <Element name="element10" className="element">
        <Section10 />
      </Element>
    ),
  },

  {
    id: 11,
    name: "Section11",
    component: (
      <Element name="element11" className="element">
        <Section11 />
      </Element>
    ),
  },
  {
    id: 12,
    name: "Section14",
    component: (
      <Element name="element12" className="element">
        <Section14 />
      </Element>
    ),
  },

  {
    id: 13,
    name: "Section13",
    component: (
      <Element name="element13" className="element">
        {" "}
        <Section13 />
      </Element>
    ),
  },
];
export default router;

