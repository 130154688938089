const emailTemplateContactUs = (
  firstName,
  lastName,
  title,
  company,
  email,
  phone,
  message
) => {
  return `<html>
         <head>
         </head>
         <body>
         <p>Hello Admin,</p>

         <div style="margin-left:20px; margin-bottom:20px">
         <p>You have received a new message from the Contact Us form on your web site. Below are the details of the submission:</p>
         <ul>
         <li>Name: ${firstName + " " + lastName}</li>
        <li>Title: ${title}</li>
        <li>Company: ${company}</li>
         <li>Email: ${email}</li>   
         <li>Phone Number: ${phone}</li>
         <li>Message: ${message} Please review and respond to the visitor at your earliest convenience.</li>
         <li>Date: ${new Date()}</li>
         </ul>
         </div>
         <p>Thank you,</p>
         <p>SimpleSMS Team.</p>
         <br/>
        <p>This is an automated message. Please do not reply to this email.</p>
         </body>
         </html>
         `;
};

const emailTemplateJoinUs = (email) => {
  return `<html>
         <head>
         </head>
         <body>
         <p>Hello Admin,</p>

         <div style="margin-left:20px; margin-bottom:20px">
         <p>You have received a new message from the simpler SMS Management form on your web site. Below are the details of the submission:</p>
         <p>Details:</p>
         <ul>
         <li>Email: ${email}</li>
         <li>Date: ${new Date()}</li>
         </ul>
         </div>
         <p>Thank you,</p>
         <p>SimpleSMS Team.</p>
        <br/>
        <p>This is an automated message. Please do not reply to this email.</p>
         </body>
         </html>
         `;
};
export { emailTemplateContactUs, emailTemplateJoinUs };
