import * as React from "react";
import Image3 from "../../assets/images/image3.png";
import Image4 from "../../assets/images/image4.png";
import Image5 from "../../assets/images/image5.png";
import Image6 from "../../assets/images/image6.png";
import Image7 from "../../assets/images/image7.png";
function Section2() {
  return (
    <section className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-4 md:pt-12 max-md:pt-5">
      <div className="flex justify-center items-center py-8 max-md:px-5 w-full">
        <div className="w-full">
          <div className="flex flex-col max-md:flex-col max-md:gap-0 justify-center items-center">
            <div className="flex flex-col max-md:ml-0 max-md:w-full justify-center items-center">
              <div className="self-stretch my-auto text-[34.98px] font-bold leading-[48.97px] max-md:mt-2 max-md:max-w-full max-md:text-center">
                Leading Brands Achieving Excellence with Our Solutions
              </div>
            </div>
            <div className="flex flex-col max-md:ml-0 mt-10 max-md:w-full justify-center items-center">
              <div className="grow max-md:max-w-full">
                <div className="flex gap-8 flex-wrap justify-between items-center max-md:flex-wrap max-md:justify-center max-md:pr-5">
                  <img
                    loading="lazy"
                    src={Image3}
                    alt="Image3"
                    className="shrink-0 self-stretch my-auto max-w-full aspect-[2.94] w-[190px]"
                  />
                  <img
                    loading="lazy"
                    src={Image4}
                    alt="Image4"
                    className="shrink-0 self-stretch my-auto w-32 max-w-full aspect-[1.49]"
                  />
                  <img
                    loading="lazy"
                    src={Image5}
                    alt="Image5"
                    className="shrink-0 self-stretch my-auto max-w-full aspect-[4.76] w-[247px]"
                  />
                  <img
                    loading="lazy"
                    src={Image6}
                    alt="Image6"
                    className="shrink-0 self-stretch my-auto max-w-full aspect-[2.27] w-[235px]"
                  />
                  <img
                    loading="lazy"
                    src={Image7}
                    alt="Image7"
                    className="shrink-0 self-stretch max-w-full aspect-[1.75] w-[180px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Section2;

