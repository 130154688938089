import * as React from "react";
import section11 from "../../assets/gif/section11.gif";
function Section11() {
  return (
    <section className="bg-sky md:py-8">
      <div className="flex justify-center items-center md:py-8 max-md:px-5 max-w-7xl ">
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="self-center w-full max-md:max-w-full md:px-16">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0 justify-center items-center">
              <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
                <div className="text-[36px] font-bold leading-[50px] max-md:mt-10 max-md:text-center">
                  Streamline Sales with Smart SMS Automation
                </div>
              </div>
              <div className="border-2 border-grey-200 border-solid h-[75px] max-md:hidden"></div>
              <div className="flex flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full">
                <div className="self-stretch my-auto text-[20px] font-medium leading-[34px] max-md:mt-10 max-lg:text-center max-md:max-w-full">
                  Automate your outreach to nurture customer relationships and
                  drive consistent revenue growth effortlessly.
                </div>
              </div>
            </div>
          </div>
          <div className="max-lg:px-10 max-xl:h-[100%] w-full">
            <div className="flex flex-col mt-8 w-full max-md:my-10">
              <img
                loading="lazy"
                src={section11}
                className="w-full"
                alt="img-data"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Section11;

