import router from "./routes";

const HomePage = () => {
  return (
    <div>
      {router.map((item) => (
        <div key={item.id}>{item.component}</div>
      ))}
    </div>
  );
};
export default HomePage;
