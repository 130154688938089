import * as React from "react";
import { Link } from "react-router-dom";
import Profile from "../../assets/profile.png";
import CustomCarousel from "../CustomCarousel";
import { TextContent } from "./textContent";

function Section6() {
  return (
    <section className="bg-sky max-md:py-5 md:py-8 lg:py-14 lg:px-4">
      <div className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-4">
        <div className="flex flex-col items-center md:w-full max-md:max-w-full">
          <div className="text-[40px] font-bold text-center leading-[66px] max-md:max-w-full">
            Start Your SMS Success Story- No Sign-Up Required
          </div>
          <div className="mt-6 text-[24px] font-medium leading-[34px] text-center max-md:max-w-full">
            Explore simpleSMS’s capabilities without any signup or providing
            credit card information
            <br></br>Start your free trial today and experience the difference!
          </div>
          <div className="flex justify-between max-md:justify-center mt-12 text-lg font-semibold text-center max-md:flex-wrap max-md:mt-10">
            <Link
              to="/contact-us"
              without="true"
              rel="noreferrer"
              className="justify-center px-5 py-[8px] my-auto max-md:ml-5 max-md:mb-3 text-white bg-blue-400 rounded h-[45px] hover:bg-white hover:text-[#46a0ee] hover:border-[#46a0ee]"
            >
              Get Quote Today
            </Link>{" "}
            <Link
              without="true"
              rel="noreferrer"
              className="justify-center px-5 md:ml-10 py-2 max-md:ml-5 max-md:mb-3 rounded border border-solid border-zinc-800 h-[45px] hover:bg-zinc-800  hover:text-white"
              to="https://calendly.com/simplesms-sales/30min?month=2024-08"
            >
              Request a Demo
            </Link>
          </div>
          <div className="mt-12 max-md:flex-wrap max-md:mt-10 px-5 lg:px-6 overflow-hidden">
            <CustomCarousel
              itemsToShow={4}
              dotsNav={true}
              autoplay={false}
              infinite={true}
            >
              {TextContent.map((item, index) => (
                <div className="flex items-stretch" key={item.title}>
                  <div className="flex w-[318px] flex-col align-stretch mx-2 px-5 py-8 bg-white rounded-xl border border-solid shadow-lg border-stone-300 max-md:px-5 position-relative">
                    <div className="flex gap-5 font-bold">
                      <img
                        loading="lazy"
                        src={Profile}
                        className="shrink-0 rounded-full aspect-square w-[70px]"
                        alt="img-profile"
                      />
                      <div className="flex flex-col">
                        <div className="text-[20px] leading-[34px] font-bold ">
                          {item?.title}
                        </div>
                        <div className="mt-1 text-lg text-gray-400">
                          @RosenZOne
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 text-[16px] font-medium leading-[28px] mr-5">
                      {item?.description}
                    </div>
                    <span className="text-[50.13px] leading-[87.72px] font-normal text-gray-200 opacity-20 position-absolute -bottom-3 right-5 font-rambla">
                      0{index + 1}
                    </span>
                  </div>
                </div>
              ))}
            </CustomCarousel>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Section6;
