import * as React from "react";
import section5 from "../../assets/gif/section5.gif";

function Section5() {
  return (
    <section className="mx-auto flex max-w-7xl items-center justify-between px-4 max-md:pt-5">
      <div className="flex justify-center align-center md:px-8 max-md:px-5 lg:my-5">
        <div className="flex max-lg:flex-col max-md:gap-0">
          <div className="flex flex-col w-[45%] max-lg:ml-0 max-lg:w-full max-lg:items-center items-center">
            {" "}
            <img
              loading="lazy"
              src={section5}
              className="w-full max-lg:w-[100%] xl:w-[298.02px] h-[616.9px] xl:h-full"
              alt="arrow"
            />
          </div>
          <div className="flex flex-col ml-5 w-[55%] max-md:ml-0 max-lg:w-full max-md:mt-5 align-center justify-center mb-8">
            <div className="flex flex-col max-md:mt-5 max-md:max-w-full">
              <div className="text-4xl font-bold leading-[50px]  max-md:max-w-full max-lg:text-center">
                Allow customers to continue from where they left off
              </div>
              <div className="flex flex-col justify-center px-5 py-3.5 mt-10 rounded-lg border-2 border-gray-200 border-solid max-md:max-w-full">
                <div className="flex gap-5 justify-between">
                  <span className="mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="21"
                      viewBox="0 0 29 21"
                      fill="none">
                      <path
                        d="M15.4069 9.14733L1.46518 0.635183C0.367593 -0.0420526 -0.531499 1.98965 0.36759 3.08724L5.51691 9.39254C5.99565 9.97636 5.99565 11.0272 5.51691 11.6111L0.36759 17.9047C-0.531499 19.0139 0.367593 21.034 1.46518 20.3684L15.4069 11.8446C16.2359 11.3308 16.2359 9.66109 15.4069 9.14733Z"
                        fill="#232323"
                      />
                      <path
                        d="M28.263 9.14733L14.3329 0.635183C13.2354 -0.0420526 12.3246 1.98965 13.2237 3.08724L18.373 9.39254C18.8517 9.97636 18.8517 11.0272 18.373 11.6111L13.2237 17.9047C12.3246 19.0139 13.2354 21.034 14.3329 20.3684L28.263 11.8446C29.1037 11.3308 29.1037 9.66109 28.263 9.14733Z"
                        fill="#46A0EE"
                      />
                    </svg>
                  </span>
                  <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                    Effortless Re-engagement
                  </div>
                </div>
                <div className="mt-2.5 text-[20px] font-medium leading-[34px] md:ml-12  max-md:max-w-full">
                  Invite customers back to their last viewed item or page
                  seamlessly.
                </div>
              </div>
              <div className="flex flex-col justify-center px-5 py-3.5 mt-8 rounded-lg border-2 border-gray-200 border-solid max-md:max-w-full">
                <div className="flex gap-5 justify-between">
                  <span className="mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="21"
                      viewBox="0 0 29 21"
                      fill="none">
                      <path
                        d="M15.4069 9.14733L1.46518 0.635183C0.367593 -0.0420526 -0.531499 1.98965 0.36759 3.08724L5.51691 9.39254C5.99565 9.97636 5.99565 11.0272 5.51691 11.6111L0.36759 17.9047C-0.531499 19.0139 0.367593 21.034 1.46518 20.3684L15.4069 11.8446C16.2359 11.3308 16.2359 9.66109 15.4069 9.14733Z"
                        fill="#232323"
                      />
                      <path
                        d="M28.263 9.14733L14.3329 0.635183C13.2354 -0.0420526 12.3246 1.98965 13.2237 3.08724L18.373 9.39254C18.8517 9.97636 18.8517 11.0272 18.373 11.6111L13.2237 17.9047C12.3246 19.0139 13.2354 21.034 14.3329 20.3684L28.263 11.8446C29.1037 11.3308 29.1037 9.66109 28.263 9.14733Z"
                        fill="#46A0EE"
                      />
                    </svg>
                  </span>
                  <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                    Personalized Welcome Back
                  </div>
                </div>
                <div className="mt-2.5 text-[20px] font-medium leading-[34px] md:ml-12  max-md:max-w-full">
                  We remember your choices to make checkout a breeze.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Section5;
