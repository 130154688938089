import * as React from "react";
import Chart from "../../assets/chart.png";
import Message from "../../assets/message.png";
import Profile from "../../assets/profile-male.png";
import RecentCampaignFlows from "../../assets/recent_campaign_flows.png";
import { Link } from "react-router-dom";
function Section7() {
  return (
    <section className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-4 md:py-16 max-md:pt-5 max-md:mb-10">
      <div className="flex flex-col max-md:px-5">
        <div className="w-full max-md:max-w-full">
          <div className="flex gap-5 max-lg:flex-col max-md:gap-0">
            <div className="flex flex-col w-[40%] max-md:ml-0 max-lg:w-full ">
              <div className="flex flex-col max-lg:flex-col-reverse items-center max-lg:w-full">
                <div className="flex flex-col -mt-2 justify-center items-start max-md:items-center self-stretch pl-16 text-sm rounded-none max-md:px-5 max-md:mt-10 max-md:max-w-full position-relative max-md:px-16">
                  <img
                    loading="lazy"
                    src={RecentCampaignFlows}
                    alt="RecentCampaignFlows"
                    className="z-10 xl:mt-16 w-full max-md:w-[50%]"
                  />
                  <span
                    className="hidden lg:flex"
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      zIndex: 0,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="640"
                      height="244"
                      viewBox="0 0 640 244"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_773_2781)">
                        <path
                          d="M0 122C0 54.6213 54.6213 0 122 0H640V244H122C54.6213 244 0 189.379 0 122Z"
                          fill="url(#paint0_linear_773_2781)"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_773_2781"
                          x1="34.3831"
                          y1="118.963"
                          x2="521.301"
                          y2="215.164"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#46A0EE" stopOpacity="0.22" />
                          <stop
                            offset="1"
                            stopColor="#46A0EE"
                            stopOpacity="0"
                          />
                        </linearGradient>
                        <clipPath id="clip0_773_2781">
                          <rect width="640" height="244" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </div>
                <div className="pt-5 md:pl-28 xl:pl-40 text-[40px] -mt-1 z-20 bg-white font-bold leading-[60px] max-md:mt-10 max-md:max-w-full md:-mr-10 xl:-mr-24 text-center">
                  Fully Managed SMS Services at Zero Cost
                </div>
              </div>
            </div>
            <div className="flex flex-col md:w-[60%] max-md:ml-0 max-md:w-full max-md:px-5">
              <div className="flex flex-col grow md:mt-7 max-md:max-w-full position-relative">
                <img
                  src={Chart}
                  alt="Message"
                  className="z-10"
                  loading="lazy"
                />

                <div className="lg:mt-6 max-md:max-w-full">
                  <div className="flex gap-5 max-md:gap-0">
                    <div className="flex flex-col w-[60%] ml-16 max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col grow justify-center items-end max-md:items-start lg:mt-5 text-xs leading-4 rounded-none text-neutral-800 max-md:max-w-full">
                        <img
                          loading="lazy"
                          src={Message}
                          alt="Message"
                          className="z-10 max-md:w-full"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col md:w-[40%] max-md:ml-0 max-md:w-full z-10 -ml-36 max-md:px-5">
                      <div className="flex w-[348px] flex-col py-5 mx-auto w-full text-sm whitespace-nowrap bg-white rounded-lg border border-solid border-neutral-300 shadow-[0px_7px_22px_rgba(0,0,0,0.02)]">
                        <div className="flex gap-5 justify-center pr-5 pl-5 font-medium leading-[143%] text-slate-800 max-md:pr-5">
                          <img
                            loading="lazy"
                            src={Profile}
                            className="shrink-0 aspect-square w-[60px]"
                            alt="arrow"
                          />

                          <div className="w-full my-auto text-[14px] leading-[20px] font-medium text-blue-100  font-Roboto">
                            +123456789
                          </div>
                        </div>
                        <div
                          style={{ border: "1px solid #EAEAEA" }}
                          className="mt-3"
                        >
                          {" "}
                        </div>
                        <div className="mx-5 leading-[157%]  max-md:mx-2.5 max-md:mt-10">
                          <div className="mt-3 text-[16px] leading-[20px] font-bold text-blue-100">
                            Personal info
                          </div>
                          <div className="mt-3 text-[14px] leading-[20px] font-medium text-blue-100 font-Roboto">
                            Location
                          </div>
                          <div className="mt-1 text-[14px] leading-[22px] font-normal text-gray-100  font-Roboto">
                            US
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  className="hidden lg:flex "
                  style={{ position: "absolute", bottom: 30, right: "20%" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="606"
                    height="244"
                    viewBox="0 0 606 244"
                    fill="none"
                  >
                    <path
                      d="M606 122C606 54.6213 551.379 0 484 0H0V244H484C551.379 244 606 189.379 606 122Z"
                      fill="url(#paint0_linear_773_2783)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_773_2783"
                        x1="573.443"
                        y1="118.963"
                        x2="110.595"
                        y2="205.55"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#46A0EE" stopOpacity="0.22" />
                        <stop offset="1" stopColor="#46A0EE" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="self-start z-20 pt-2 xl:-mt-5 text-xl font-medium w-full leading-9 bg-white max-md:max-w-full max:md:pl-0 md:pl-52 xl:pl-36 max-md:text-center max-md:mt-5  max-md:px-5">
          Unlock growth without extra fees. our dedicated SMS expert is with you
          at every step.
        </div>
        <div className="lg:mt-14 max-md:mt-5 w-full">
          <div className="flex gap-5 max-lg:flex-col max-md:gap-0 max-lg:w-full">
            <div className="flex flex-col w-[50%] max-md:ml-0 max-lg:w-full items-center">
              <div className="flex flex-col text-2xl font-bold leading-9  max-md:mt-8 max-md:max-w-full">
                <div className="flex gap-5 justify-between">
                  <span className="mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="21"
                      viewBox="0 0 29 21"
                      fill="none"
                    >
                      <path
                        d="M15.4069 9.14733L1.46518 0.635183C0.367593 -0.0420526 -0.531499 1.98965 0.36759 3.08724L5.51691 9.39254C5.99565 9.97636 5.99565 11.0272 5.51691 11.6111L0.36759 17.9047C-0.531499 19.0139 0.367593 21.034 1.46518 20.3684L15.4069 11.8446C16.2359 11.3308 16.2359 9.66109 15.4069 9.14733Z"
                        fill="#232323"
                      />
                      <path
                        d="M28.263 9.14733L14.3329 0.635183C13.2354 -0.0420526 12.3246 1.98965 13.2237 3.08724L18.373 9.39254C18.8517 9.97636 18.8517 11.0272 18.373 11.6111L13.2237 17.9047C12.3246 19.0139 13.2354 21.034 14.3329 20.3684L28.263 11.8446C29.1037 11.3308 29.1037 9.66109 28.263 9.14733Z"
                        fill="#46A0EE"
                      />
                    </svg>
                  </span>
                  <div className="w-full max-md:max-w-full">
                    Automate your SMS campaign flows.
                  </div>
                </div>
                <div className="flex gap-5 justify-between mt-8">
                  <span className="mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="21"
                      viewBox="0 0 29 21"
                      fill="none"
                    >
                      <path
                        d="M15.4069 9.14733L1.46518 0.635183C0.367593 -0.0420526 -0.531499 1.98965 0.36759 3.08724L5.51691 9.39254C5.99565 9.97636 5.99565 11.0272 5.51691 11.6111L0.36759 17.9047C-0.531499 19.0139 0.367593 21.034 1.46518 20.3684L15.4069 11.8446C16.2359 11.3308 16.2359 9.66109 15.4069 9.14733Z"
                        fill="#232323"
                      />
                      <path
                        d="M28.263 9.14733L14.3329 0.635183C13.2354 -0.0420526 12.3246 1.98965 13.2237 3.08724L18.373 9.39254C18.8517 9.97636 18.8517 11.0272 18.373 11.6111L13.2237 17.9047C12.3246 19.0139 13.2354 21.034 14.3329 20.3684L28.263 11.8446C29.1037 11.3308 29.1037 9.66109 28.263 9.14733Z"
                        fill="#46A0EE"
                      />
                    </svg>
                  </span>
                  <div className="w-full max-md:max-w-full">
                    Test and refine message impact.
                  </div>
                </div>
                <div className="flex gap-5 justify-between mt-8">
                  <span className="mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="21"
                      viewBox="0 0 29 21"
                      fill="none"
                    >
                      <path
                        d="M15.4069 9.14733L1.46518 0.635183C0.367593 -0.0420526 -0.531499 1.98965 0.36759 3.08724L5.51691 9.39254C5.99565 9.97636 5.99565 11.0272 5.51691 11.6111L0.36759 17.9047C-0.531499 19.0139 0.367593 21.034 1.46518 20.3684L15.4069 11.8446C16.2359 11.3308 16.2359 9.66109 15.4069 9.14733Z"
                        fill="#232323"
                      />
                      <path
                        d="M28.263 9.14733L14.3329 0.635183C13.2354 -0.0420526 12.3246 1.98965 13.2237 3.08724L18.373 9.39254C18.8517 9.97636 18.8517 11.0272 18.373 11.6111L13.2237 17.9047C12.3246 19.0139 13.2354 21.034 14.3329 20.3684L28.263 11.8446C29.1037 11.3308 29.1037 9.66109 28.263 9.14733Z"
                        fill="#46A0EE"
                      />
                    </svg>
                  </span>
                  <div className="w-full max-md:max-w-full">
                    Boost your sign-up rates easily.
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[50%] max-md:ml-0 max-lg:w-full max-lg:items-center">
              <div className="flex flex-col grow text-2xl font-bold leading-9  max-md:mt-8 max-md:max-w-full">
                <div className="flex gap-5 justify-between">
                  <span className="mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="21"
                      viewBox="0 0 29 21"
                      fill="none"
                    >
                      <path
                        d="M15.4069 9.14733L1.46518 0.635183C0.367593 -0.0420526 -0.531499 1.98965 0.36759 3.08724L5.51691 9.39254C5.99565 9.97636 5.99565 11.0272 5.51691 11.6111L0.36759 17.9047C-0.531499 19.0139 0.367593 21.034 1.46518 20.3684L15.4069 11.8446C16.2359 11.3308 16.2359 9.66109 15.4069 9.14733Z"
                        fill="#232323"
                      />
                      <path
                        d="M28.263 9.14733L14.3329 0.635183C13.2354 -0.0420526 12.3246 1.98965 13.2237 3.08724L18.373 9.39254C18.8517 9.97636 18.8517 11.0272 18.373 11.6111L13.2237 17.9047C12.3246 19.0139 13.2354 21.034 14.3329 20.3684L28.263 11.8446C29.1037 11.3308 29.1037 9.66109 28.263 9.14733Z"
                        fill="#46A0EE"
                      />
                    </svg>
                  </span>
                  <div className="w-full max-md:max-w-full">
                    Strategy sessions with clear reports.
                  </div>
                </div>
                <div className="flex gap-5 justify-between mt-8">
                  <span className="mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="21"
                      viewBox="0 0 29 21"
                      fill="none"
                    >
                      <path
                        d="M15.4069 9.14733L1.46518 0.635183C0.367593 -0.0420526 -0.531499 1.98965 0.36759 3.08724L5.51691 9.39254C5.99565 9.97636 5.99565 11.0272 5.51691 11.6111L0.36759 17.9047C-0.531499 19.0139 0.367593 21.034 1.46518 20.3684L15.4069 11.8446C16.2359 11.3308 16.2359 9.66109 15.4069 9.14733Z"
                        fill="#232323"
                      />
                      <path
                        d="M28.263 9.14733L14.3329 0.635183C13.2354 -0.0420526 12.3246 1.98965 13.2237 3.08724L18.373 9.39254C18.8517 9.97636 18.8517 11.0272 18.373 11.6111L13.2237 17.9047C12.3246 19.0139 13.2354 21.034 14.3329 20.3684L28.263 11.8446C29.1037 11.3308 29.1037 9.66109 28.263 9.14733Z"
                        fill="#46A0EE"
                      />
                    </svg>
                  </span>
                  <div className="w-full max-md:max-w-full">
                    Align SMS with your marketing dates.
                  </div>
                </div>
                <Link
                  without="true"
                  rel="noreferrer"
                  className="justify-center self-start px-5 py-2 mt-7 text-lg font-semibold text-center text-white bg-blue-400 rounded max-lg:self-center  h-[45px] hover:bg-white hover:text-[#46a0ee] hover:border-[#46a0ee]"
                  to="https://calendly.com/simplesms-sales/30min?month=2024-08"
                >
                  Book a Demo
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Section7;
