import * as React from "react";
import ReactSimplyCarousel from "react-simply-carousel";

const CustomCarousel = ({
  activeIndex = 0,
  dotsNav = true,
  itemsToShow = 2,
  children,
  ...props
}) => {
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(activeIndex);
  return (
    <ReactSimplyCarousel
      activeSlideIndex={activeSlideIndex}
      onRequestChange={setActiveSlideIndex}
      containerProps={{
        style: {
          width: "100%",
          justifyContent: "space-between",
          userSelect: "none",
          overflow: "hidden",
        },
      }}
      preventScrollOnSwipe
      swipeTreshold={10}
      //   forwardBtnProps={{
      //     children: ">",
      //     style: {
      //       width: 60,
      //       height: 60,
      //       minWidth: 60,
      //       alignSelf: "center",
      //     },
      //   }}
      //   backwardBtnProps={{
      //     children: "<",
      //     style: {
      //       width: 60,
      //       height: 60,
      //       minWidth: 60,
      //       alignSelf: "center",
      //     },
      //   }}
      dotsNav={{
        show: dotsNav,
        itemBtnProps: {
          style: {
            marginTop: 50,
            height: 10,
            width: 10,
            marginLeft: 10,
            borderRadius: "50%",
            border: 0,
            background: "#FFF",
          },
        },
        activeItemBtnProps: {
          style: {
            marginTop: 50,
            height: 10,
            width: 30,
            marginLeft: 10,
            borderRadius: 10,
            border: 0,
            background: "#46a0ee",
          },
        },
      }}
      responsiveProps={[
        {
          itemsToShow: 0.5,
          itemsToScroll: 1,
          maxWidth: 536,
        },
        {
          itemsToShow: 1,
          itemsToScroll: 1,
          minWidth: 536,
          maxWidth: 768,
        },
        {
          itemsToShow: 1,
          itemsToScroll: 1,
          minWidth: 768,
          maxWidth: 992,
        },
        {
          itemsToShow: 1.8,
          itemsToScroll: 1,
          minWidth: 992,
          maxWidth: 1024,
        },
        {
          itemsToShow: 2.2,
          itemsToScroll: 2,
          minWidth: 1024,
          maxWidth: 1280,
        },
        {
          itemsToShow: 2.8,
          itemsToScroll: 2,
          minWidth: 1280,
          maxWidth: 1360,
        },
        {
          itemsToShow: 4,
          itemsToScroll: 2,
          minWidth: 1360,
          maxWidth: 1536,
        },
      ]}
      itemsToShow={itemsToShow}
      speed={600}
      centerMode
      {...props}>
      {children}
    </ReactSimplyCarousel>
  );
};
export default CustomCarousel;
