import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import CustomFooter from "./components/CustomFooter/CustomFooter";
import ErrorBoundary from "./components/ErrorBoundary";
import Header from "./components/header";
import CookiePolicy from "./pages/CookiePolicy";
import HomePage from "./pages/HomePage/HomePage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondition from "./pages/TermsAndCondition";
import CCPA from "./pages/CCPA";
import ContactUsPage from "./pages/ContactUsPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AboutUs from "./pages/AboutUs";

function App() {
  return (
    <ErrorBoundary>
      <div className="bg-white">
        <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<HomePage />}></Route>
            <Route exact path="/contact-us" element={<ContactUsPage />}></Route>
            <Route
              path="/term-and-conditions"
              element={<TermsAndCondition />}
            ></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route
              path="/cookie-policy"
              element={<CookiePolicy />}
            ></Route>{" "}
            <Route path="/ccpa-privacy-policy" element={<CCPA />}></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
          </Routes>
          <CustomFooter />
          <ToastContainer />
        </Router>
      </div>
    </ErrorBoundary>
  );
}

export default App;
