import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <section className="max-w-7xl mx-auto max-xl:px-5 py-20 max-md:py-10 flex items-start md:pl-8">
      <h1 className="flex self-center justify-center items-center text-4xl font-bold">
        About Us
      </h1>
      <br /> <br />
      <div>
        Welcome to Simplesms! We’re all about helping businesses enhance their
        marketing strategies through the power of SMS. In a fast-paced world,
        clear and effective communication is essential, and we’re committed to
        ensuring your message gets to your audience loud and clear.
      </div>
      <br />
      <div>
        At Simplesms, we focus on personalized SMS marketing that truly reflects
        your brand. Our innovative approach can help you boost your SMS sales by
        1.5X to 2X. We believe every message should strike a chord with your
        customers, fostering meaningful connections that drive engagement and
        increase sales.
      </div>
      <br />
      <div>
        Whether you’re a budding startup or a well-established business, our
        team is here to deliver customized solutions that cater to your specific
        needs. We aim to make SMS marketing straightforward, impactful, and
        enjoyable!
      </div>
      <br />
      <div>
        Join us on this exciting journey to revolutionize how you connect with
        your audience. Let’s take your marketing to the next level together!
      </div>
    </section>
  );
};
export default AboutUs;
