const Content = [
  {
    id: 1,
    title: "Silver",
    planM: "6 months",
    planY: "1 year",
    monthPriceSMS: "$0.0098",
    yearPriceSMS: "$0.0080",
    monthPriceMMS: "$0.0275",
    yearPriceMMS: "$0.0255",
    description:
      " Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    buttonText: "Select",
  },
  {
    id: 2,
    title: "Gold",
    planM: "6 months",
    planY: "1 year",
    monthPriceSMS: "$0.0080",
    yearPriceSMS: "$0.0068",
    monthPriceMMS: "$0.0255",
    yearPriceMMS: "$0.0240",
    description:
      " Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    buttonText: "Select",
  },
];
export default Content;
