import * as React from "react";
import Section31 from "../../assets/gif/section3-1.gif";
import Section32 from "../../assets/gif/section3-2.gif";
import Section33 from "../../assets/gif/section3-3.gif";

function Section3() {
  return (
    <section className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-4 md:py-20 max-md:py-12 max-md:my-5">
      <h3 className="text-[40px] font-bold leading-[46.96px] flex justify-center items-center text-center">
        Exclusive Features Offered By SimpleSMS{" "}
      </h3>
      <div className="flex flex-col md:mt-10 max-md:mt-8">
        <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold border-red-200 border-solid border-2 rounded-lg bg-red-100">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[42%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-end items-center px-8 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                <img
                  src={Section31}
                  loading="lazy"
                  alt="Exclusive"
                  className="w-full lg:h-[400px]"
                />
              </div>
            </div>
            <div className="flex flex-col w-[58%] max-md:ml-0 max-md:w-full md:py-10 max-md:mb-5 -ml-10">
              <div className="flex flex-col grow px-2 max-md:mt-10 max-md:max-w-full">
                <h6 className="text-[36px] font-bold leading-[50px] max-md:max-w-full max-md:text-center">
                  Boost your SMS sales by 1.5X-2X with driven personalization
                </h6>
                <div className="flex flex-col justify-center px-8 py-3.5 mt-8 rounded-lg border-2 border-red-200 border-solid max-md:max-w-full">
                  <div className="flex gap-5 justify-between">
                    <span className="mt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="20"
                        viewBox="0 0 29 20"
                        fill="none"
                      >
                        <path
                          d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                          fill="#232323"
                        />
                        <path
                          d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                          fill="#F79292"
                        />
                      </svg>
                    </span>
                    <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                      AI-Boosted Sales
                    </div>
                  </div>
                  <h6 className="mt-2.5 text-[20px] font-medium leading-[34px] md:ml-12  max-md:max-w-full">
                    Skyrocket engagement and sales with tailored AI insights.
                  </h6>
                </div>
                <div className="flex flex-col justify-center px-5 py-3.5 mt-5 rounded-lg border-2 border-red-200 border-solid max-md:max-w-full">
                  <div className="flex gap-5 justify-between">
                    <span className="mt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="20"
                        viewBox="0 0 29 20"
                        fill="none"
                      >
                        <path
                          d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                          fill="#232323"
                        />
                        <path
                          d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                          fill="#F79292"
                        />
                      </svg>
                    </span>
                    <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                      Smart Messaging
                    </div>
                  </div>
                  <div className="mt-2.5 text-[20px] font-medium leading-[34px] md:ml-12  max-md:max-w-full">
                    Craft precise messages that connect with your target
                    audience and convert
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:mt-24 w-full max-md:mt-8 max-md:max-w-full border-yellow-200 border-solid border-2 rounded-lg bg-yellow-100 ">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0 md:mt-3">
            <div className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full max-md:py-5 ml-6 md:mt-5">
              <div className="flex flex-col grow px-6 max-md:max-w-full md:mb-8">
                <div className="text-[36px] font-bold leading-[50px] max-md:max-w-full max-md:text-center">
                  Seize the Moment: Smart SMS Timing
                </div>
                <div className="flex flex-col justify-center px-5 py-3.5 mt-8 border-yellow-200 border-solid border-2 rounded-lg max-md:max-w-full">
                  <div className="flex gap-5 justify-between">
                    <span className="mt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="20"
                        viewBox="0 0 29 20"
                        fill="none"
                      >
                        <path
                          d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                          fill="#232323"
                        />
                        <path
                          d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                          fill="#E8D277"
                        />
                      </svg>
                    </span>
                    <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                      Smart Scheduling
                    </div>
                  </div>
                  <div className="mt-2.5 text-[20px] font-medium leading-[34px] md:ml-12  max-md:max-w-full">
                    Send messages according to the past purchase behaviors of
                    customers.
                  </div>
                </div>
                <div className="flex flex-col justify-center px-5 py-3.5 mt-8 rounded-lg border-2 border-yellow-200 border-solid max-md:max-w-full">
                  <div className="flex gap-5 justify-between ">
                    <span className="mt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="20"
                        viewBox="0 0 29 20"
                        fill="none"
                      >
                        <path
                          d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                          fill="#232323"
                        />
                        <path
                          d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                          fill="#E8D277"
                        />
                      </svg>
                    </span>
                    <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                      Action-Driven Texts
                    </div>
                  </div>
                  <div className="mt-2.5 text-[20px] font-medium leading-[34px] md:ml-12  max-md:max-w-full">
                    Tailor delivery to maximize customer response rates.
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[40%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow md:justify-end max-md:justify-center max-md:pl-5 max-md:mt-10 pr-4 max-md:max-w-full max-lg:h-[449px]">
                <img
                  src={Section32}
                  loading="lazy"
                  alt="Exclusive"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="md:mt-24 w-full max-md:mt-8 max-md:max-w-full border-pruple-200 border-solid border-2 rounded-lg bg-pruple-100">
          <div className="flex gap-5 py-4 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[40%] max-md:ml-0 max-md:w-full justify-center items-center">
              <div className="flex flex-col grow justify-center items-center max-md:mt-5 max-md:max-w-full -mr-20">
                <img
                  src={Section33}
                  loading="lazy"
                  alt="Exclusive"
                  className="xl:h-[525px] max-md:h-[350px]"
                />
              </div>
            </div>
            <div className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch my-auto px-8 max-md:mt-10 max-md:max-w-full md:py-5 max-md:py-5">
                <div className="text-[36px] font-bold leading-[50px] max-md:max-w-full max-md:text-center">
                  Set Up Auto Reminders for Almost-Bought Items
                </div>
                <div className="flex flex-col justify-center px-5 py-3.5 mt-8 rounded-lg border-2 border-pruple-200 border-solid max-md:max-w-full">
                  <div className="flex gap-5 justify-between">
                    <span className="mt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="20"
                        viewBox="0 0 29 20"
                        fill="none"
                      >
                        <path
                          d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                          fill="#232323"
                        />
                        <path
                          d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                          fill="#8C8CE8"
                        />
                      </svg>
                    </span>
                    <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                      Friendly Reminders
                    </div>
                  </div>
                  <div className="mt-2.5 text-[20px] font-medium leading-[34px] md:ml-12  max-md:max-w-full">
                    Gently remind customers about items left in their carts.
                  </div>
                </div>
                <div className="flex flex-col justify-center px-5 py-3.5 mt-8 rounded-lg border-2 border-pruple-200 border-solid max-md:max-w-full">
                  <div className="flex gap-5 justify-between">
                    <span className="mt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="20"
                        viewBox="0 0 29 20"
                        fill="none"
                      >
                        <path
                          d="M15.4069 8.64733L1.46518 0.135183C0.367593 -0.542053 -0.531499 1.48965 0.36759 2.58724L5.51691 8.89254C5.99565 9.47636 5.99565 10.5272 5.51691 11.1111L0.36759 17.4047C-0.531499 18.5139 0.367593 20.534 1.46518 19.8684L15.4069 11.3446C16.2359 10.8308 16.2359 9.16109 15.4069 8.64733Z"
                          fill="#232323"
                        />
                        <path
                          d="M28.263 8.64733L14.3329 0.135183C13.2354 -0.542053 12.3246 1.48965 13.2237 2.58724L18.373 8.89254C18.8517 9.47636 18.8517 10.5272 18.373 11.1111L13.2237 17.4047C12.3246 18.5139 13.2354 20.534 14.3329 19.8684L28.263 11.3446C29.1037 10.8308 29.1037 9.16109 28.263 8.64733Z"
                          fill="#8C8CE8"
                        />
                      </svg>
                    </span>
                    <div className="w-full max-md:max-w-full text-[22px] leading-[34px] font-bold">
                      Save on Remarketing
                    </div>
                  </div>
                  <div className="mt-2.5 text-[20px] font-medium leading-[34px] md:ml-12  max-md:max-w-full">
                    Send timely offers, spend less, and sell more with smart
                    follow-ups.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Section3;

