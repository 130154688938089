import * as React from "react";
import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

function Header() {
  const [closeMenu, setCloseMenu] = React.useState(false);
  const scrollData = (key) => {
    scroller.scrollTo(key, {
      duration: 2000,
      delay: 0,
      smooth: true,
    });
  };
  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-4 "
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/">
            <img
              src={Logo}
              className="shrink-0 self-stretch max-w-full aspect-[4.17] w-[246px]"
              alt="logo"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setCloseMenu(true)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-8">
          <div className="relative">
            <button
              type="button"
              className="flex items-center gap-2.5 text-sm font-semibold leading-6 text-gray-900"
              aria-expanded="false"
              onClick={() => scrollData("element3")}
            >
              Features{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M16.3337 7.5L10.5003 13.3333L4.66699 7.5"
                  stroke="#111827"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="relative">
            <button
              type="button"
              className="flex items-center gap-2.5 text-sm font-semibold leading-6 text-gray-900"
              aria-expanded="false"
              onClick={() => scrollData("element12")}
            >
              Price{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M16.3337 7.5L10.5003 13.3333L4.66699 7.5"
                  stroke="#111827"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="relative">
            <button
              type="button"
              className="flex items-center gap-2.5 text-sm font-semibold leading-6 text-gray-900"
              aria-expanded="false"
              onClick={() => scrollData("element2")}
            >
              Company{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M16.3337 7.5L10.5003 13.3333L4.66699 7.5"
                  stroke="#111827"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          {/* <div className="relative">
            <button
              type="button"
              className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
              aria-expanded="false"
            >
              Price
            </button>
          </div> */}
        </div>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-5 font-semibold text-center text-white">
          <Link
            without="true"
            rel="noreferrer"
            className="justify-center px-5 py-[10px] my-auto bg-blue-400 rounded h-[45px] hover:bg-white hover:text-[#46a0ee] hover:border-[#46a0ee]"
            to="https://calendly.com/simplesms-sales/30min?month=2024-08"
          >
            Book a Demo
          </Link>
          {/* <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4f721872938c4864866731baf681c0aaa59b2f311e277bed1d64e7ff020083b?"
            classNameName="shrink-0 aspect-square w-[50px]"
            alt="profile"
          /> */}
        </div>
      </nav>
      {closeMenu && (
        <div className="lg:hidden" role="dialog" aria-modal="true">
          <div
            className="fixed inset-0 z-10"
            style={{
              background: "rgba(0,0,0, 0.2)",
            }}
          ></div>
          <div className="fixed inset-y-0 right-0 z-10 w-[50%] overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <span></span>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setCloseMenu(false)}
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <div className="-mx-3">
                    <button
                      type="button"
                      onClick={() => {
                        scrollData("element3");
                        setCloseMenu(false);
                      }}
                      className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      aria-controls="disclosure-1"
                      aria-expanded="false"
                    >
                      Features
                      <svg
                        className="h-5 w-5 flex-none"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="-mx-3">
                    <button
                      type="button"
                      className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      aria-controls="disclosure-1"
                      onClick={() => {
                        scrollData("element12");
                        setCloseMenu(false);
                      }}
                      aria-expanded="false"
                    >
                      Price
                      <svg
                        className="h-5 w-5 flex-none"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="-mx-3">
                    <button
                      type="button"
                      className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      aria-controls="disclosure-1"
                      aria-expanded="false"
                      onClick={() => {
                        scrollData("element2");
                        setCloseMenu(false);
                      }}
                    >
                      Company
                      <svg
                        className="h-5 w-5 flex-none"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}
export default Header;
