import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CookiePolicy = () => {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <section className="max-w-7xl mx-auto max-xl:px-5 py-20 max-md:px-8 max-md:py-10 flex items-start md:pl-8">
      <h1 className="flex self-center justify-center items-center text-4xl font-bold">
        Cookie Policy
      </h1>
      <div className="font-bold text-xl mt-10">
        Last Updated: <span className="font-normal ">02-08-2024</span>
      </div>
      <div>
        This Cookie Policy explains what cookies are and how we use them on{" "}
        <a
          href={process.env.REACT_APP_URL || "https://staging.simplesms.com/"}
          className="underline text-blue-600"
        >
          {process.env.REACT_APP_URL || "https://staging.simplesms.com/"}
        </a>
        operated by simple sms.
      </div>
      <div className="font-bold mt-5">1. What are Cookies?</div>
      <div>
        Cookies are small text files that a website may place on your computer,
        phone, or other device when you visit a site. The cookie typically
        contains information about your visit, such as your unique browser
        identifier, preferences (e.g., language settings), and other browsing
        data.
      </div>
      <div className="font-bold mt-5">2. How We Use Cookies</div>
      <div>We use cookies for the following purposes:</div>
      <ul className="md:ml-10">
        <li className="font-bold mt-3 list-disc">
          Essential Cookies:
          <span className="font-normal">
            These cookies are strictly necessary for the operation of our
            Website. They allow you to navigate the Website and use its
            features, such as accessing secure areas.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Performance Cookies:
          <span className="font-normal">
            These cookies collect information about how you use our Website,
            such as which pages you visit and how long you spend on a page. This
            information helps us improve the performance of our Website and user
            experience.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Functionality Cookies:
          <span className="font-normal">
            These cookies allow us to remember your preferences (e.g., language)
            and provide you with a more personalized experience.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Analytics and Advertising Cookies:
          <span className="font-normal">
            These cookies may be used to track your browsing activity across
            different websites and build a profile of your interests. This
            information may be used to show you targeted advertising on our
            Website or other websites.
          </span>
        </li>
      </ul>
      <div className="font-bold mt-5">3. Your Cookie Choices</div>
      <div>You have a number of options regarding cookies:</div>
      <ul className="md:ml-10">
        <li className="font-bold mt-3 list-disc">
          Accepting All Cookies:
          <span className="font-normal">
            You can accept all cookies by continuing to use our Website.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Managing Cookies in Your Browser:
          <span className="font-normal">
            Most web browsers allow you to control cookies through their
            settings. You can usually choose to block cookies altogether, or to
            receive a notification before a cookie is stored.
          </span>
        </li>
      </ul>
      <div>
        Please note that disabling certain cookies may limit your ability to use
        certain features of our Website.
      </div>
      <div className="font-bold mt-5">4. Further Information</div>
      <div>
        For further information about cookies, please visit{" "}
        <a
          href="https://optout.aboutads.info/"
          className="underline text-blue-600"
        >
          https://optout.aboutads.info/
        </a>{" "}
        or{" "}
        <a
          href="https://youronlinechoices.eu/"
          className="underline text-blue-600"
        >
          https://youronlinechoices.eu/.
        </a>{" "}
      </div>
      <div className="font-bold mt-5">5. Contact Us</div>
      <div>
        If you have any questions about our Cookie Policy, please contact us at{" "}
        <a
          href={
            "mailto:" + process.env.REACT_APP_EMAIL ||
            "mailto:admin@simplesms.com"
          }
        >
          {process.env.REACT_APP_EMAIL || "admin@simplesms.com"}
        </a>
      </div>
    </section>
  );
};

export default CookiePolicy;
