import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import sendBrevoEmail from "../../apis/email";
import { emailTemplateContactUs } from "../../utils/template";

function ContactUsPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  function isValidPhone(phone) {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone);
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const onSubmit = async () => {
    if (
      company !== "" &&
      title !== "" &&
      lastName !== "" &&
      firstName !== "" &&
      phone !== "" &&
      message !== "" &&
      email !== ""
    ) {
      if (!isValidEmail(email)) {
        toast("Email is invalid! ");
      } else if (!isValidPhone(phone)) {
        toast("Phone number is invalid");
      } else {
        await sendBrevoEmail(
          "POST",
          {},
          {
            to: [
              {
                email:
                  process.env.REACT_APP_BREVO_RECEIVER || "admin@simplesms.com",
                name: "Simple SMS Admin",
              },
            ],
            subject: "New Form Submission Received",
            body: emailTemplateContactUs(
              firstName,
              lastName,
              title,
              company,
              email,
              phone,
              message
            ),
          }
        );
        toast("Successfully submitted!");
        setPhone("");
        setEmail("");
        setCompany("");
        setFirstName("");
        setLastName("");
        setMessage("");
      }
    } else if (
      company === "" &&
      title === "" &&
      lastName === "" &&
      firstName === "" &&
      phone === "" &&
      message === "" &&
      email === ""
    ) {
      toast("All field is Required!");
    } else if (firstName === "") {
      toast("First name is Required!");
    } else if (lastName === "") {
      toast("Last name is Required!");
    } else if (company === "") {
      toast("Company is Required!");
    } else if (title === "") {
      toast("Title is Required!");
    } else if (email === "") {
      toast("Email is Required!");
    } else if (phone === "") {
      toast("Phone is Required!");
    } else if (message === "") {
      toast("Message is Required!");
    }
  }; // Automatically scrolls to top whenever pathname changes
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <section className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-4 md:py-20 max-md:py-5 max-md:my-5">
      <h3 className="text-[40px] font-bold leading-[46.96px] flex justify-center items-center text-center">
        Get in Touch With Us
      </h3>
      <div className="w-[50%] max-md:w-full">
        <div className="flex flex-col mt-8">
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name*"
            className="border rounded-sm my-2 p-4"
          />
        </div>
        <div className="flex flex-col my-3">
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name*"
            className="border rounded-sm my-2 p-4"
          />
        </div>
        <div className="flex flex-col">
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title*"
            className="border rounded-sm my-2 p-4"
          />
        </div>
        <div className="flex flex-col">
          <input
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            placeholder="Company*"
            className="border rounded-sm my-2 p-4"
          />
        </div>
        <div className="flex flex-col">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address*"
            className="border rounded-sm my-2 p-4"
          />
        </div>
        <div className="flex flex-col my-3">
          <input
            value={phone}
            type="number"
            placeholder="Phone Number*"
            onChange={(e) => setPhone(e.target.value)}
            className="border rounded-sm my-2 p-4"
          />
        </div>
        <div className="flex flex-col my-3">
          <textarea
            value={message}
            type="number"
            placeholder="Message"
            onChange={(e) => setMessage(e.target.value)}
            className="border rounded-sm my-2 p-4"
          />
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-5 font-semibold text-center text-white">
          <button
            className="justify-center px-5 py-2 my-auto bg-blue-400 rounded h-[45px] hover:bg-white hover:text-[#46a0ee] hover:border-[#46a0ee]"
            onClick={() => onSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </section>
  );
}
export default ContactUsPage;

