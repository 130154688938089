import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <section className="max-w-7xl mx-auto max-xl:px-5 py-20 max-md:py-10 max-md:px-8 flex items-start md:pl-8">
      <h1 className="flex self-center justify-center items-center text-4xl font-bold">
        Privacy Policy
      </h1>
      <div className="font-bold text-xl mt-10">
        Last Updated: <span className="font-normal ">02-08-2024</span>
      </div>
      <div>
        This Privacy Policy describes how Simple SMS collects, uses, and
        discloses your personal information when you use our website{" "}
        <a
          href={process.env.REACT_APP_URL || "https://staging.simplesms.com/"}
          className="underline text-blue-600"
        >
          {process.env.REACT_APP_URL || "https://staging.simplesms.com/"}
        </a>
      </div>
      <div className="font-bold mt-5">1. Information We Collect</div>
      <div>
        We may collect the following information from you when you use our
        Website:
      </div>

      <ul className="md:ml-10">
        <li className="font-bold mt-3 list-disc">
          Personal Information:
          <span className="font-normal">
            This may include your name, email address, phone number, and mailing
            address. We will only collect this information if you voluntarily
            provide it to us, such as when you sign up for our SMS marketing
            list.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          TCPA and CTIA Compliance:
          <span className="font-normal">
            All SMS messages sent through our platform will comply with the
            Telephone Consumer Protection Act (TCPA) and the Cellular
            Telecommunications Industry Association (CTIA) guidelines. We will
            not send unsolicited SMS messages or messages to Do Not Call (DNC)
            registered numbers.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Usage Data:
          <span className="font-normal">
            We may collect information about how you use our Website, such as
            the pages you visit, the time you spend on each page, and the links
            you click.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Device Information:
          <span className="font-normal">
            We may collect information about your device, such as your IP
            address, browser type, operating system, and device identifier.
          </span>
        </li>
      </ul>
      <div className="font-bold mt-5">2. How We Use Your Information</div>
      <div>We may use your information for the following purposes:</div>
      <ul className="md:ml-10">
        <li className="font-bold mt-3 list-disc">
          To provide you with our SMS marketing and transactional services
        </li>
        <li className="font-bold mt-3 list-disc">
          To personalize your experience on our Website
        </li>{" "}
        <li className="font-bold mt-3 list-disc">
          To improve our Website and services
        </li>{" "}
        <li className="font-bold mt-3 list-disc">
          To analyze how you use our Website
        </li>{" "}
        <li className="font-bold mt-3 list-disc">
          To send you marketing communications (with your consent)
        </li>{" "}
        <li className="font-bold mt-3 list-disc">
          To comply with legal and regulatory requirements
        </li>
      </ul>
      <div className="font-bold mt-5">3. Sharing Your Information</div>
      <div>
        We may share your information with third-party service providers who
        help us operate our website and deliver our services. These service
        providers are contractually obligated to keep your information
        confidential and secure.
      </div>
      <div>
        We will not share your information with any third-party for marketing
        purposes without your consent.
      </div>
      <div>
        We may disclose your information to the following entities, all of which
        have agreed to comply with this Privacy Policy and implement appropriate
        security measures to protect your information:
      </div>
      <ul className="md:ml-10">
        <li className="font-bold mt-3 list-disc">
          Affiliated Companies:
          <span className="font-normal">
            We may share your information with our affiliated companies,
            including subsidiaries and sister companies, for the purposes
            described in this Privacy Policy. These affiliated companies are
            bound by this Privacy Policy and will treat your information in
            accordance with its terms.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Limited Disclosure:
          <span className="font-normal">
            We will only disclose the minimum amount of information necessary to
            achieve the intended purpose.
          </span>
        </li>
      </ul>
      <div className="font-bold mt-5">4. Data Retention</div>
      <div>
        We will retain your information for as long as necessary to fulfill the
        purposes for which it was collected and as permitted by law.
      </div>
      <div className="font-bold mt-5">5. Your Data Protection Rights</div>
      <div>You have certain rights regarding your personal information:</div>
      <ul className="md:ml-10">
        <li className="font-bold mt-3 list-disc">
          Right to access:
          <span className="font-normal">
            You have the right to request access to your personal information.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Right to rectification:
          <span className="font-normal ">
            You have the right to request that we rectify any inaccuracies in
            your personal information.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Right to erasure:
          <span className="font-normal">
            You have the right to request that we erase your personal
            information.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Right to object:
          <span className="font-normal">
            You have the right to object to the processing of your personal
            information.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Right to restriction of processing:
          <span className="font-normal">
            You have the right to request that we restrict the processing of
            your personal information.
          </span>
        </li>
        <li className="font-bold mt-3 list-disc">
          Right to data portability:
          <span className="font-normal">
            You have the right to request that we transfer your personal
            information to another controller.
          </span>
        </li>
      </ul>
      <div className="font-bold mt-5">6. Children's Privacy</div>
      <div>
        Our Website is not intended for children under the age of 13. We do not
        knowingly collect personal information from children under 13.
      </div>
    </section>
  );
};

export default PrivacyPolicy;
