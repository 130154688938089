import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CCPA = () => {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <section className="max-w-7xl mx-auto max-xl:px-5 max-md:px-8 py-20 max-md:py-10 flex items-start md:pl-8">
      <h1 className="flex self-center justify-center items-center text-4xl font-bold">
        CCPA Privacy Policy
      </h1>
      <div className="font-bold text-xl mt-10">
        Last Updated: <span className="font-normal ">02-08-2024</span>
      </div>
      <div>
        This California Consumer Privacy Act (CCPA) Privacy Policy ("Policy")
        applies to{" "}
        <a
          href={process.env.REACT_APP_URL || "https://staging.simplesms.com/"}
          className="underline text-blue-600"
        >
          {process.env.REACT_APP_URL || "https://staging.simplesms.com/"}
        </a>
        . This Policy describes the categories of personal information we
        collect, how we use it, and your rights under the CCPA.
      </div>
      <div className="font-bold mt-5">1. Information We Collect</div>
      <div>
        We collect the following categories of personal information when you
        opt-in to receive bulk promotional SMS messages from our Website:
      </div>
      <ul className="md:ml-10">
        <li className="font-bold mt-3 list-disc">
          Identifiers
          <span className="font-normal">
            This may include your phone number for sending SMS messages.
          </span>
        </li>
      </ul>
      <div className="font-bold mt-5">2. Use of Personal Information</div>
      <div>
        We use the personal information we collect for the following purposes:
      </div>{" "}
      <ul className="md:ml-10">
        <li className="list-disc">
          <span className="font-normal">
            To send you bulk promotional SMS messages about our products and
            services, as you have opted-in for.
          </span>
        </li>
        <li className="list-disc">
          <span className="font-normal">
            To personalize the content of the SMS messages we send you.
          </span>
        </li>
        <li className="list-disc">
          <span className="font-normal">
            To comply with legal and regulatory requirements.
          </span>
        </li>
      </ul>
      <div className="font-bold mt-5">3. Sharing of Personal Information</div>{" "}
      <div>
        We may share your personal information with third-party service
        providers who help us operate our Website and send SMS messages. We will
        only share your personal information with service providers that have
        agreed to use it in accordance with this Policy and applicable law.
      </div>{" "}
      <div className="font-bold mt-5">4. Your CCPA Rights</div>{" "}
      <div>
        Under the CCPA, California residents have certain rights regarding their
        personal information. These rights include the right to:
      </div>{" "}
      <ul className="md:ml-10">
        <li className="font-bold mt-3 list-disc">
          Know what personal information we collect, use, and disclose about
          you.
        </li>
        <li className="font-bold mt-3 list-disc">
          Request deletion of your personal information.
        </li>
        <li className="font-bold mt-3 list-disc">
          Opt-out of the sale of your personal information (We do not sell your
          personal information).
        </li>
      </ul>
      <div className="font-bold mt-5">5. How to Exercise Your CCPA Rights</div>
      <div>
        You can exercise your CCPA rights by contacting us at [Your Email
        Address] or by calling us at [Your Phone Number]. We will verify your
        request before processing it.
      </div>
      <div className="font-bold mt-5">
        6. California Do Not Track Disclosure
      </div>
      <div>
        We do not currently respond to web browser "Do Not Track" signals.
      </div>{" "}
      <div className="font-bold mt-5">7. Changes to this Policy</div>
      <div>
        We may update this Policy from time to time. We will post any changes on
        this page.
      </div>
      <div className="font-bold mt-5">8. Contact Us</div>
      <div>
        If you have any questions about these Terms, please contact us at{" "}
        <a
          href={
            "mailto:" + process.env.REACT_APP_EMAIL ||
            "mailto:admin@simplesms.com"
          }
        >
          {process.env.REACT_APP_EMAIL || "admin@simplesms.com"}
        </a>
      </div>
    </section>
  );
};
export default CCPA;
